export interface Locale {
    name: string;
    locale: string;
}

export const LOCALES =
    [
        {"name": "Afrikaans (South Africa)", locale: "af-ZA"},
        {"name": "Albanian (Albania)", locale: "sq-AL"},
        {"name": "Arabic (Algeria)", locale: "ar-DZ"},
        {"name": "Arabic (Bahrain)", locale: "ar-BH"},
        {"name": "Arabic (Egypt)", locale: "ar-EG"},
        {"name": "Arabic (Iraq)", locale: "ar-IQ"},
        {"name": "Arabic (Jordan)", locale: "ar-JO"},
        {"name": "Arabic (Kuwait)", locale: "ar-KW"},
        {"name": "Arabic (Lebanon)", locale: "ar-LB"},
        {"name": "Arabic (Libya)", locale: "ar-LY"},
        {"name": "Arabic (Morocco)", locale: "ar-MA"},
        {"name": "Arabic (Oman)", locale: "ar-OM"},
        {"name": "Arabic (Qatar)", locale: "ar-QA"},
        {"name": "Arabic (Saudi Arabia)", locale: "ar-SA"},
        {"name": "Arabic (Syria)", locale: "ar-SY"},
        {"name": "Arabic (Tunisia)", locale: "ar-TN"},
        {"name": "Arabic (United Arab Emirates)", locale: "ar-AE"},
        {"name": "Arabic (Yemen)", locale: "ar-YE"},
        {"name": "Armenian (Armenia)", locale: "hy-AM"},
        {"name": "Azerbaijani (Azerbaijan)", locale: "az-AZ"},
        {"name": "Basque (Spain)", locale: "eu-ES"},
        {"name": "Belarusian (Belarus)", locale: "be-BY"},
        {"name": "Bengali (India)", locale: "bn-IN"},
        {"name": "Bosnian (Bosnia and Herzegovina)", locale: "bs-BA"},
        {"name": "Bulgarian (Bulgaria)", locale: "bg-BG"},
        {"name": "Catalan (Spain)", locale: "ca-ES"},
        {"name": "Chinese (China)", locale: "zh-CN"},
        {"name": "Chinese (Hong Kong SAR China)", locale: "zh-HK"},
        {"name": "Chinese (Macao SAR China)", locale: "zh-MO"},
        {"name": "Chinese (Singapore)", locale: "zh-SG"},
        {"name": "Chinese (Taiwan)", locale: "zh-TW"},
        {"name": "Croatian (Croatia)", locale: "hr-HR"},
        {"name": "Czech (Czech Republic)", locale: "cs-CZ"},
        {"name": "Danish (Denmark)", locale: "da-DK"},
        {"name": "Dutch (Belgium)", locale: "nl-BE"},
        {"name": "Dutch (Netherlands)", locale: "nl-NL"},
        {"name": "English (Australia)", locale: "en-AU"},
        {"name": "English (Belize)", locale: "en-BZ"},
        {"name": "English (Canada)", locale: "en-CA"},
        {"name": "English (Ireland)", locale: "en-IE"},
        {"name": "English (Jamaica)", locale: "en-JM"},
        {"name": "English (New Zealand)", locale: "en-NZ"},
        {"name": "English (Philippines)", locale: "en-PH"},
        {"name": "English (South Africa)", locale: "en-ZA"},
        {"name": "English (Trinidad and Tobago)", locale: "en-TT"},
        {"name": "English (U.S. Virgin Islands)", locale: "en-VI"},
        {"name": "English (United Kingdom)", locale: "en-GB"},
        {"name": "English (United States)", locale: "en-US"},
        {"name": "English (Zimbabwe)", locale: "en-ZW"},
        {"name": "Estonian (Estonia)", locale: "et-EE"},
        {"name": "Faroese (Faroe Islands)", locale: "fo-FO"},
        {"name": "Finnish (Finland)", locale: "fi-FI"},
        {"name": "French (Belgium)", locale: "fr-BE"},
        {"name": "French (Canada)", locale: "fr-CA"},
        {"name": "French (France)", locale: "fr-FR"},
        {"name": "French (Luxembourg)", locale: "fr-LU"},
        {"name": "French (Monaco)", locale: "fr-MC"},
        {"name": "French (Switzerland)", locale: "fr-CH"},
        {"name": "Galician (Spain)", locale: "gl-ES"},
        {"name": "Georgian (Georgia)", locale: "ka-GE"},
        {"name": "German (Austria)", locale: "de-AT"},
        {"name": "German (Germany)", locale: "de-DE"},
        {"name": "German (Liechtenstein)", locale: "de-LI"},
        {"name": "German (Luxembourg)", locale: "de-LU"},
        {"name": "German (Switzerland)", locale: "de-CH"},
        {"name": "Greek (Greece)", locale: "el-GR"},
        {"name": "Gujarati (India)", locale: "gu-IN"},
        {"name": "Hebrew (Israel)", locale: "he-IL"},
        {"name": "Hindi (India)", locale: "hi-IN"},
        {"name": "Hungarian (Hungary)", locale: "hu-HU"},
        {"name": "Icelandic (Iceland)", locale: "is-IS"},
        {"name": "Indonesian (Indonesia)", locale: "id-ID"},
        {"name": "Italian (Italy)", locale: "it-IT"},
        {"name": "Italian (Switzerland)", locale: "it-CH"},
        {"name": "Japanese (Japan)", locale: "ja-JP"},
        {"name": "Kannada (India)", locale: "kn-IN"},
        {"name": "Kazakh (Kazakhstan)", locale: "kk-KZ"},
        {"name": "Konkani (India)", locale: "kok-IN"},
        {"name": "Korean (South Korea)", locale: "ko-KR"},
        {"name": "Latvian (Latvia)", locale: "lv-LV"},
        {"name": "Lithuanian (Lithuania)", locale: "lt-LT"},
        {"name": "Macedonian (Macedonia)", locale: "mk-MK"},
        {"name": "Malay (Brunei)", locale: "ms-BN"},
        {"name": "Malay (Malaysia)", locale: "ms-MY"},
        {"name": "Malayalam (India)", locale: "ml-IN"},
        {"name": "Maltese (Malta)", locale: "mt-MT"},
        {"name": "Marathi (India)", locale: "mr-IN"},
        {"name": "Mongolian (Mongolia)", locale: "mn-MN"},
        {"name": "Northern Sami (Norway)", locale: "se-NO"},
        {"name": "Norwegian Bokmål (Norway)", locale: "nb-NO"},
        {"name": "Norwegian Nynorsk (Norway)", locale: "nn-NO"},
        {"name": "Persian (Iran)", locale: "fa-IR"},
        {"name": "Polish (Poland)", locale: "pl-PL"},
        {"name": "Portuguese (Brazil)", locale: "pt-BR"},
        {"name": "Portuguese (Portugal)", locale: "pt-PT"},
        {"name": "Punjabi (India)", locale: "pa-IN"},
        {"name": "Romanian (Romania)", locale: "ro-RO"},
        {"name": "Russian (Russia)", locale: "ru-RU"},
        {"name": "Serbian (Bosnia and Herzegovina)", locale: "sr-BA"},
        {"name": "Serbian (Serbia And Montenegro)", locale: "sr-CS"},
        {"name": "Slovak (Slovakia)", locale: "sk-SK"},
        {"name": "Slovenian (Slovenia)", locale: "sl-SI"},
        {"name": "Spanish (Argentina)", locale: "es-AR"},
        {"name": "Spanish (Bolivia)", locale: "es-BO"},
        {"name": "Spanish (Chile)", locale: "es-CL"},
        {"name": "Spanish (Colombia)", locale: "es-CO"},
        {"name": "Spanish (Costa Rica)", locale: "es-CR"},
        {"name": "Spanish (Dominican Republic)", locale: "es-DO"},
        {"name": "Spanish (Ecuador)", locale: "es-EC"},
        {"name": "Spanish (El Salvador)", locale: "es-SV"},
        {"name": "Spanish (Guatemala)", locale: "es-GT"},
        {"name": "Spanish (Honduras)", locale: "es-HN"},
        {"name": "Spanish (Mexico)", locale: "es-MX"},
        {"name": "Spanish (Nicaragua)", locale: "es-NI"},
        {"name": "Spanish (Panama)", locale: "es-PA"},
        {"name": "Spanish (Paraguay)", locale: "es-PY"},
        {"name": "Spanish (Peru)", locale: "es-PE"},
        {"name": "Spanish (Puerto Rico)", locale: "es-PR"},
        {"name": "Spanish (Spain)", locale: "es-ES"},
        {"name": "Spanish (Uruguay)", locale: "es-UY"},
        {"name": "Spanish (Venezuela)", locale: "es-VE"},
        {"name": "Swahili (Kenya)", locale: "sw-KE"},
        {"name": "Swedish (Finland)", locale: "sv-FI"},
        {"name": "Swedish (Sweden)", locale: "sv-SE"},
        {"name": "Syriac (Syria)", locale: "syr-SY"},
        {"name": "Tamil (India)", locale: "ta-IN"},
        {"name": "Telugu (India)", locale: "te-IN"},
        {"name": "Thai (Thailand)", locale: "th-TH"},
        {"name": "Tswana (South Africa)", locale: "tn-ZA"},
        {"name": "Turkish (Turkey)", locale: "tr-TR"},
        {"name": "Ukrainian (Ukraine)", locale: "uk-UA"},
        {"name": "Uzbek (Uzbekistan)", locale: "uz-UZ"},
        {"name": "Vietnamese (Vietnam)", locale: "vi-VN"},
        {"name": "Welsh (United Kingdom)", locale: "cy-GB"},
        {"name": "Xhosa (South Africa)", locale: "xh-ZA"},
        {"name": "Zulu (South Africa)", locale: "zu-ZA"}]
