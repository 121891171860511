import * as React from 'react';
import { FC, ReactNode, useEffect, useState } from 'react';
import { CredentialType, credentialTypeSelector, getCredentialTypes } from '../../../state/slices/credentialtype';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../state';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import { DataViewPageEvent } from 'primereact/dataview';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { CredentialKindText, CredentialTypeCard, DeploymentEnvironmentText, LocaleAutoComplete, LocaleSelector, OPaginator, ReadMorePanel } from '../../molecules';
import { CredentialTypeSearchEntry } from '../../organisms';
import { CredentialTypeSearchForm } from '../../../state/slices/credentialtypesearchform';
import { getStaticData, staticDataSelector } from '../../../state/slices/staticdata';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getUserPreferenceWithDefault, updateUserPreference, userPreferenceSelector } from '../../../state/slices/userpreference';


export const CredentialTypeList: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {keycloak} = useKeycloak();
    const [searchForm, setSearchForm] = useState<CredentialTypeSearchForm>();
    let userPreference = useSelector(userPreferenceSelector).singleItem;

    const initialParams: DataViewPageEvent = {
        first: 0,
        rows: Number(getUserPreferenceWithDefault(userPreference, 'credentialTypeList.pageSize', "10")),
        page: 0,
        pageCount: 1
    };
    const [tableParams, setTableParams] = useState<DataViewPageEvent>(initialParams);

    let credentialTypes = useSelector(credentialTypeSelector);
    let staticData = useSelector(staticDataSelector);


    useEffect(() => {
        dispatch(getStaticData({}));
    }, []);

    useEffect(() => {
        dispatch(getCredentialTypes({
            jwtToken: keycloak.token!,
            locale: userPreference?.locale,
            page: tableParams.page,
            pageSize: (tableParams.rows),
            credentialTypeSearchForm: searchForm
        }));
    }, [keycloak.token, userPreference?.locale, tableParams.page, tableParams.rows, searchForm]);


    useEffect(() => {
        setTableParams({...tableParams, pageCount: credentialTypes.totalPages});
    }, [credentialTypes.totalPages]);

    function onSearch(form: CredentialTypeSearchForm | undefined) {
        setSearchForm(Object.assign({}, form));
        setTableParams({...tableParams, page: 0});
    }

    function onPageChange(paginatorPageChangeEvent: PaginatorPageChangeEvent) {
        setTableParams({...tableParams, page: paginatorPageChangeEvent.page, rows: paginatorPageChangeEvent.rows});
    }

    function onCredentialClicked(credentialType: CredentialType) {
        navigate('/credentialType/' + credentialType.id);
    }

    const logoTemplate = (credentialType: CredentialType) => {
        if (credentialType.display?.logo?.url) {
            return (
                <img src={credentialType.display?.logo?.url} alt="{credentialType.display?.logo?.altText}" style={{
                    maxHeight: '30px',
                    maxWidth: '70px',
                    height: 'auto',
                    width: 'auto'
                }}/>
            )
        } else if (credentialType.issuerDisplay?.logo?.url) {
            return (
                <img src={credentialType.issuerDisplay?.logo?.url} alt="{credentialType.issuerDisplay?.logo?.altText}" style={{
                    maxHeight: '30px',
                    maxWidth: '70px',
                    height: 'auto',
                    width: 'auto'
                }}/>
            )
        } else {
            return null;
        }
    };

    const itemTemplate = (credentialType: any): ReactNode => {
        return (
            <div className="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-3 xxl:col-2 pb-5 p-2">
                <CredentialTypeCard
                    credentialType={credentialType}
                    credentialTypeDisplay={credentialType.display} key='{index}'
                    issuerDisplay={credentialType.issuerDisplay}
                    onClick={onCredentialClicked}
                />
            </div>
        );
    };

    return (
        <div className="grid">
            <div className="col-2">
                <CredentialTypeSearchEntry title={t('screens.credentialTypeList.title')}
                                           placeHolder={t('screens.credentialTypeList.freeSearchPlaceholder')}
                                           onSearch={onSearch} className="mt-5 mb-4"/>
            </div>
            <div className="col-10">
                <ReadMorePanel className="mt-4 mb-6" classNameBody2="mt-4" bodyPart1={(
                    <span>{t('screens.credentialTypeList.body1')}</span>
                )} bodyPart2={(
                    <span>{t('screens.credentialTypeList.body2')}</span>
                    )}
                />
                {/*<LocaleSelector*/}
                {/*    className="flex justify-content-end mb-1"*/}
                {/*    allowedLocales={staticData.singleItem?.locale}*/}
                {/*    selectedLocale={userPreference?.locale}*/}
                {/*    onLocaleSelected={(locale) => {*/}
                {/*        dispatch(updateUserPreference({locale: locale, userPreferences: userPreference?.userPreferences}));*/}
                {/*    }}*/}
                {/*/>*/}
                <LocaleAutoComplete
                    className="flex justify-content-end mb-1"
                    selectedLocale={userPreference?.locale} onLocaleSelected={(locale) => {
                    dispatch(updateUserPreference({locale: locale, userPreferences: userPreference?.userPreferences}));
                }}
                />
                <DataTable value={credentialTypes.list} tableStyle={{minWidth: '50rem'}} selectionMode="single" onSelectionChange={(e) => {
                    onCredentialClicked(e.value);
                }}>
                    <Column header="" body={logoTemplate}></Column>
                    <Column header={t('fields.issuanceConfig.issuerName.typeDescription')} body={(credentialType: CredentialType) => credentialType.issuerDisplay?.name}></Column>
                    <Column header={t('fields.issuanceConfigCredentialType.credentialType.typeDescription')} body={(credentialType: CredentialType) => credentialType.display?.name}></Column>
                    <Column header={t('fields.issuanceConfigCredentialType.credentialFormat.typeDescription')} body={(credentialType: CredentialType) => credentialType.credentialFormat}></Column>
                    <Column header={t('fields.issuanceConfigCredentialType.credentialKind.typeDescription')} body={(credentialType: CredentialType) => <CredentialKindText credentialKind={credentialType.credentialKind}></CredentialKindText>}></Column>
                    <Column header={t('fields.issuanceConfig.deploymentEnvironment.typeDescription')} body={(credentialType: CredentialType) => <DeploymentEnvironmentText deploymentEnvironment={credentialType.deploymentEnvironment}></DeploymentEnvironmentText>}></Column>
                </DataTable>

                {((credentialTypes.list.length > 0) && (credentialTypes.totalPages > 1)) && (
                    <div className="flex justify-content-end">
                        <OPaginator first={credentialTypes.currentPage * tableParams.rows} rows={tableParams.rows} totalRecords={credentialTypes.totalElements} onPageChange={onPageChange} userPreferencesKey={"credentialTypeList"}/>
                    </div>
                )}

                {/*<DataView*/}
                {/*    value={credentialTypes.list}*/}
                {/*    layout='grid'*/}
                {/*    className={''}*/}
                {/*    itemTemplate={itemTemplate}*/}
                {/*    emptyMessage={t('screens.search.searchNoCredentialTypesFound')}*/}
                {/*    rows={credentialTypes.list.length}*/}
                {/*    totalRecords={credentialTypes.totalElements}/>*/}
                {/*{((credentialTypes.list.length > 0) && (credentialTypes.totalPages > 1)) && (*/}
                {/*    <div className="flex justify-content-end">*/}
                {/*        <OPaginator first={credentialTypes.currentPage * tableParams.rows} rows={tableParams.rows} totalRecords={credentialTypes.totalElements} onPageChange={onPageChange} userPreferencesKey={"credentialTypeList"}/>*/}
                {/*    </div>*/}
                {/*)}*/}

            </div>
        </div>
    );
}
